import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  data() {
    return {
      loadingAddons: []
    };
  },
  computed: {
    ...mapFields('addon', {
      addonIds: 'allIds',
      addonsById: 'byId',
      addonIdsCached: 'cacheAllIds'
    }),
    ...mapGetters('addon', {
      findAddon: 'find'
    }),
    groupedAddons() {
      let addons = [];
      let firstLevelAddons = [];
      let childrenAddons = [];

      Object.values(this.addonsById).forEach((addon) => {
        if (addon.parent === null) {
          firstLevelAddons.push(addon);
        } else {
          childrenAddons.push(addon);
        }
      });

      firstLevelAddons.forEach((parentAddon) => {
        if (parentAddon.children !== null) {
          addons.push(this.resolveAddonChildren(parentAddon, childrenAddons));
        }
      });
      return addons;
    }
  },
  methods: {
    ...mapActions('addon', {
      retrieveAddon: 'load',
      resetAllAddonData: 'resetAllData'
    }),
    resolveAddon(itemId) {
      if (!this.isAddonLoaded(itemId) && !this.loadingAddons.includes(itemId)) {
        this.loadingAddons.push(itemId);
        this.retrieveAddon(decodeURIComponent(itemId));
      }
      return this.findAddon(itemId);
    },
    resolveAddons(itemIds) {
      this.loadingAddons = [];
      if (typeof itemIds === 'undefined') {
        return [];
      }
      return itemIds
        .map((itemId) => {
          return this.resolveAddon(itemId);
        })
        .filter((e) => e);
    },
    getAddonItem(addonIri, type) {
      if (addonIri !== null) {
        let addon = this.resolveAddon(addonIri);
        if (addon) {
          return addon[type];
        }
      }
      return '';
    },
    isAddonLoaded(itemId) {
      return this.addonIdsCached.includes(itemId);
    },
    isAddonSetLoaded(itemIds) {
      return itemIds.every((itemId) => this.isAddonLoaded(itemId));
    },
    resolveAddonAsync(itemId) {
      const poll = (resolve) => {
        if (this.isAddonLoaded(itemId)) {
          resolve(this.resolveAddon(itemId));
        } else {
          this.resolveAddon(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      };
      return new Promise(poll);
    },
    resolveAddonsAsync(itemIds) {
      const poll = (resolve) => {
        if (this.isAddonSetLoaded(itemIds)) {
          resolve(this.resolveAddons(itemIds));
        } else {
          this.resolveAddons(itemIds);
          setTimeout(() => poll(resolve), 400);
        }
      };
      return new Promise(poll);
    },
    clearAddonData() {
      this.loadingAddons = [];
      this.resetAllAddonData();
    },
    resolveAddonChildren(currentAddon, children) {
      let childAddons = [];
      currentAddon.children.forEach((childAddon) => {
        const child = children.find((ct) => ct['@id'] === childAddon);
        if (child) {
          childAddons.push({ id: childAddon, title: child['title'] });
        }
      });

      return {
        id: currentAddon['@id'],
        title: currentAddon['title'],
        ...(childAddons.length > 0 ? { children: childAddons } : {})
      };
    },
    addonNodeNormalizer(node) {
      return {
        label: node['title'],
        children: node.children
      };
    }
  }
};
